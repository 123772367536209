import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import CFS from '../components/molecules/CFS';

import imgI1 from '../assets/images/pages/recursos-y-trabajo/thirdSectionImg.png';

import '../assets/styles/pages/recursos-y-trabajo.scss';

const RecursosYTrabajo = () => (
  <Layout
    title="Mediateca – Recursos e Ideas | ELE Barcelona"
    description="Compartimos la actualidad del mundo ELE con trabajo, actividades, blogs que nos inspiran, noticias y muchas cosas más que nos motivan. ¡Que la disfrutes!"
  >
    <div className="recursos-y-trabajo">
      <CFS title="Recursos y trabajo" />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <p className="second-section__description">
                En ELE Barcelona queremos mantenerte al día con toda la actualidad del mundo ELE
                poniendo a tu disposición recursos útiles para el profesor de español. Aquí
                encontrarás actividades e ideas para tus clases, páginas con ofertas de trabajo,
                cursos formativos, blogs inspiradores, videos, noticias de actualidad, canciones,
                manuales, editoriales, materiales auténticos, etc, etc. Queremos compartir contigo
                todo lo que nos motiva y enriquece tu carrera profesional. Te aconsejamos que te
                suscribas a sus boletines para estar 100% al día del mundo ELE.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2 className="c-title-34">Actividades e ideas para clase</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <a target="_blank" href="http://www.todoele.net" className="external-link">
                <span className="external-link__title">Todoele</span>
                <span className="external-link__description">
                  Recursos, materiales, ofertas de trabajo, cursos e información para profesores
                  ELE.
                </span>
              </a>
              <a target="_blank" href="https://www.practicaespanol.com/" className="external-link">
                <span className="external-link__title">Practica español</span>
                <span className="external-link__description">
                  Web de la agencia EFE con noticias y actividades clasificadas por niveles y
                  destrezas.
                </span>
              </a>
              <a
                target="_blank"
                href="https://cvc.cervantes.es/ensenanza/actividades_ave/aveteca.htm"
                className="external-link"
              >
                <span className="external-link__title">Aveteca</span>
                <span className="external-link__description">
                  Aula virtual de español del Instituto Cervantes.
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.imageneseducativas.com/"
                className="external-link"
              >
                <span className="external-link__title">Imágenes educativas</span>
                <span className="external-link__description">Imágenes educativas. ELE niños.</span>
              </a>
              <a target="_blank" href="http://educalab.es/recursos" className="external-link">
                <span className="external-link__title">Educalab</span>
                <span className="external-link__description">
                  Red de recursos educativos abiertos del Ministerio de educación.
                </span>
              </a>
              <a target="_blank" href="https://www.biografiasyvidas.com/" className="external-link">
                <span className="external-link__title">Biografías y vidaso</span>
                <span className="external-link__description">
                  Biografías de personajes famosos e ilustres.
                </span>
              </a>
              <a
                target="_blank"
                href="https://clic.xtec.cat/legacy/es/index.html "
                className="external-link"
              >
                <span className="external-link__title">Zonaclic</span>
                <span className="external-link__description">
                  Página gubernamental con cursos, documentos y actividades relacionadas con la
                  enseñanza de español.
                </span>
              </a>
              <a target="_blank" href="https://birlibirprofe.com/" className="external-link">
                <span className="external-link__title">Birlibirprofe</span>
                <span className="external-link__description">
                  Materiales e ideas para la clase de ELE.
                </span>
              </a>
            </div>

            <div className="col-md-6">
              <a target="_blank" href="https://profesoresdeele.org/" className="external-link">
                <span className="external-link__title">Profesores de ele</span>
                <span className="external-link__description">
                  Portal con actividades, ideas y ofertas de trabajo.
                </span>
              </a>
              <a target="_blank" href="https://marcoele.com/actividades/" className="external-link">
                <span className="external-link__title">MarcoeELE</span>
                <span className="external-link__description">
                  Revista didáctica del español como lengua extranjera con actividades clasificadas
                  por niveles.
                </span>
              </a>
              {/* <a
                target="_blank"
                href="https://espanolparainmigrantes.wordpress.com/"
                className="external-link"
              >
                <span className="external-link__title">Español para inmigrantes</span>
                <span className="external-link__description">
                  Blog de recursos para la enseñanza del español a inmigrantes y refugiados.
                </span>
              </a> */}
              <a target="_blank" href="http://formespa.rediris.es/" className="external-link">
                <span className="external-link__title">Formespa</span>
                <span className="external-link__description">
                  Espacio web para profesores de español.
                </span>
              </a>
              <a target="_blank" href="https://www.pinterest.es/" className="external-link">
                <span className="external-link__title">Pinterest</span>
                <span className="external-link__description">
                  Banco general de imágenes con múltiples actividades e ideas relacionadas con ELE.
                </span>
              </a>
              <a target="_blank" href="https://www.colorele.es/" className="external-link">
                <span className="external-link__title">colorELE</span>
                <span className="external-link__description">
                  Ideas, experiencias y recursos para la clase de español.
                </span>
              </a>
              <a target="_blank" href="https://www.rtve.es/" className="external-link">
                <span className="external-link__title">Rtve.es</span>
                <span className="external-link__description">
                  La web de Radio Televisión Española con “A la carta” y cientos de programas,
                  noticias, podcast, películas, etc, con subtítulos en español.
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="third-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="third-section__title c-title-34">Blogs inspiradores</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <a target="_blank" href="https://www.lapizdeele.com/" className="external-link">
                <span className="external-link__title">El lápiz de ele</span>
                <span className="external-link__description">
                  El blog creativo de Ana Gómez que tanto nos gusta.
                </span>
              </a>
              <a target="_blank" href="http://www.profedeele.es/" className="external-link">
                <span className="external-link__title">Profedele</span>
                <span className="external-link__description">
                  Blog para estudiantes y profesores de español.
                </span>
              </a>
              <a
                target="_blank"
                href="https://espanolparainmigrantes.wordpress.com/"
                className="external-link"
              >
                <span className="external-link__title">Español para inmigrantes</span>
                <span className="external-link__description">Español para inmigrantes</span>
              </a>
              {/* <a target="_blank" href="http://www.profeland.com/" className="external-link">
                <span className="external-link__title">Profeland</span>
                <span className="external-link__description">
                  Blog repleto de actividades, tutoriales, cine, consejos y mucho más.
                </span>
              </a> */}
              <a target="_blank" href="https://lenguajeyotrasluces.com/" className="external-link">
                <span className="external-link__title">Lenguaje y otras luces</span>
                <span className="external-link__description">
                  Súper blog repleto de infografías, plantillas, gramática, vocabulario y mucho más.
                </span>
              </a>
              <a target="_blank" href="https://estudiafeliz.com/" className="external-link">
                <span className="external-link__title">Estudia feliz</span>
                <span className="external-link__description">
                  Blog con actividades, ideas creativas e imágenes divertidas para la clase.
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.maestrosdeaudicionylenguaje.com/"
                className="external-link"
              >
                <span className="external-link__title">Maestros de audición y lenguaje</span>
                <span className="external-link__description">
                  Web especializada en logopedia y educación infantil con mucha información y
                  actividades adaptables al trabajo de la fonética en clase ELE.
                </span>
              </a>
              <a
                target="_blank"
                href="https://yohablotuhablas.com/blog-para-aprender-espanol/"
                className="external-link"
              >
                <span className="external-link__title">Yo hablo tú hablas</span>
                <span className="external-link__description">
                  Blog con ideas y recursos para tu clase ELE. También ofrece asesoría para
                  profesores.
                </span>
              </a>
            </div>

            <div className="col-md-6">
              <a target="_blank" href="http://jramonele.blogspot.com.es/" className="external-link">
                <span className="external-link__title">Jramonele.com</span>
                <span className="external-link__description">
                  Uno de los blogs más activos. Una fuente de inspiración.
                </span>
              </a>
              <a target="_blank" href="http://es.tiching.com/" className="external-link">
                <span className="external-link__title">Tiching</span>
                <span className="external-link__description">
                  Recursos educativos y una de las mejores webs del año.
                </span>
              </a>
              {/* <a
                target="_blank"
                href="http://www.cuadernointercultural.com/"
                className="external-link"
              >
                <span className="external-link__title">Educación intercultural</span>
                <span className="external-link__description">
                  Blog con recursos para trabajar la interculturalidad.
                </span>
              </a> */}
              <a target="_blank" href="http://abcdeele.com/" className="external-link">
                <span className="external-link__title">AbcdeEle</span>
                <span className="external-link__description">
                  Creatividad a tope y plantillas descargables para tus clases.
                </span>
              </a>
              <img src={imgI1} alt="Sitting person" />
            </div>
          </div>
        </div>
      </section>

      <section className="fourth-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="c-title-34">Videos en Youtube para la clase</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <a
                target="_blank"
                href="http://www.videoele.com/index.html"
                className="external-link"
              >
                <span className="external-link__title">Videoele</span>
                <span className="external-link__description">
                  Videos para aprender español como lengua extranjera.
                </span>
              </a>
              <a target="_blank" href="https://www.practicaespanol.com/" className="external-link">
                <span className="external-link__title">Practica español</span>
                <span className="external-link__description">
                  Web de la agencia EFE con noticias y actividades clasificadas por niveles y
                  destrezas.
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/user/TioSpanish"
                className="external-link"
              >
                <span className="external-link__title">Tio Spanish</span>
                <span className="external-link__description">
                  Aprender español online. Videos culturales y de vocabulario.
                </span>
              </a>
            </div>

            <div className="col-md-6">
              <a
                target="_blank"
                href="http://www.videoele.com/index.html"
                className="external-link"
              >
                <span className="external-link__title">Difusión</span>
                <span className="external-link__description">
                  Videos y material complementario para la clase.
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCTyCyyV0eKfEoMWI_V11z6A"
                className="external-link"
              >
                <span className="external-link__title">Tu clase de español</span>
                <span className="external-link__description">
                  Vídeos para aprender español y para conocer cómo es la vida en España.
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/user/SergiMartinSpanish"
                className="external-link"
              >
                <span className="external-link__title">SergiMartinSpanish</span>
                <span className="external-link__description">
                  Videos de un profesor de español.
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="fifth-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="c-title-34">Material auténtico webs</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <a
                target="_blank"
                href="https://www.godaddy.com/forsale/lyrics-training.com"
                className="external-link external-link--shortened"
              >
                <span className="external-link__title">Lyrics-training.com</span>
              </a>
              <a
                target="_blank"
                href="https://www.fotogramas.es/"
                className="external-link external-link--shortened"
              >
                <span className="external-link__title">Fotogramas.es</span>
              </a>
              <a
                target="_blank"
                href="https://www.practicaespanol.com/"
                className="external-link external-link--shortened"
              >
                <span className="external-link__title">Practicaespanol</span>
              </a>
            </div>

            <div className="col-md-6">
              <a
                target="_blank"
                href="https://elpais.com/"
                className="external-link external-link--shortened"
              >
                <span className="external-link__title">Elpais.com</span>
              </a>
              <a
                target="_blank"
                href="http://euronews.es/"
                className="external-link external-link--shortened"
              >
                <span className="external-link__title">Euronews.es</span>
              </a>
              <a
                target="_blank"
                href="https://www.rtve.es/"
                className="external-link external-link--shortened"
              >
                <span className="external-link__title">Rtve.es</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="sixth-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="c-title-34">Editoriales y revistas</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <a target="_blank" href="http://www.difusion.com/" className="external-link">
                <span className="external-link__title">Difusion</span>
                <span className="external-link__description">
                  Editorial de español como lengua extranjera con base en Barcelona.
                </span>
              </a>
              <a target="_blank" href="https://edinumen.es/" className="external-link">
                <span className="external-link__title">Edinumen</span>
                <span className="external-link__description">
                  Editorial de español como lengua extranjera.
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.educacionyfp.gob.es/portada.html"
                className="external-link"
              >
                <span className="external-link__title">Redele</span>
                <span className="external-link__description">
                  Red de didáctica de español como lengua extranjera del Ministerio de educación.
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.anayaele.es/es/portada/index.html"
                className="external-link"
              >
                <span className="external-link__title">Anaya</span>
                <span className="external-link__description">
                  La web para la enseñanza de español con manuales descargables e ideas para dar
                  clases.
                </span>
              </a>
            </div>

            <div className="col-md-6">
              <a target="_blank" href="http://edelsa.es/es/" className="external-link">
                <span className="external-link__title">Edelsa</span>
                <span className="external-link__description">
                  Editorial de español como lengua extranjera.
                </span>
              </a>

              <a target="_blank" href="http://www.sgel.es/" className="external-link">
                <span className="external-link__title">Sgel</span>
                <span className="external-link__description">
                  Editorial de español como lengua extranjera.
                </span>
              </a>
              <a target="_blank" href="http://marcoele.com/actividades/" className="external-link">
                <span className="external-link__title">MarcoELE</span>
                <span className="external-link__description">
                  Revista didáctica del español como lengua extranjera.
                </span>
              </a>

              <a target="_blank" href="https://enclave-ele.net/" className="external-link">
                <span className="external-link__title">EnclaveELE</span>
                <span className="external-link__description">
                  Editorial especializada en la enseñanza del español como lengua extranjera.
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default RecursosYTrabajo;
